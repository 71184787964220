<template>
    <div>
        <Quote title="Bu yerda siz ma'lumotlaringizni o'zgartirishingiz mumkin"/>
        <GoBack next="O'zgartirish"/>
        <div class="row justify-content-center">
            <div class="col-8">
                <form @submit.prevent="editUser" class="ABC">
                    <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">Email</label>
                        <input
                            type="email"
                            class="form-control"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            placeholder="E-mail"
                            v-model="forms.email"
                            required
                        />
                    </div>
                    <div class="mb-3">
                        <label for="exampleInputGivenName1" class="form-label">Ism</label>
                        <input
                            type="text"
                            class="form-control"
                            id="exampleInputGivenName1"
                            placeholder="Ism"
                            v-model="forms.givenName"
                            required
                        />
                    </div>
                    <div class="mb-3">
                        <label for="exampleInputFamilyName1" class="form-label">Familiya</label>
                        <input
                            type="text"
                            class="form-control"
                            id="exampleInputFamilyName1"
                            placeholder="Familiya"
                            v-model="forms.familyName"
                            required
                        />
                    </div>
                    <label for="exampleInputFamilyName1" class="form-label">Telefon raqam</label>
                    <vue-tel-input
                        class="tel"
                        id="exampleInputFamilyName1"
                        :inputOptions="options"
                        v-model="forms.phoneNumber"
                    >
                    </vue-tel-input>

                    <div class="mb-3">
                        <label for="exampleInputId1" class="form-label">Telegram login</label>
                        <input
                            type="tel"
                            class="form-control"
                            id="exampleInputId1"
                            placeholder="Telegram raqam"
                            v-model="forms.telegramNumber"
                            required
                        />
                    </div>

                    <div class="row justify-content-end mb-3">
                        <button type="submit" class="btn btn-primary">O'zgartirish</button>
                    </div>

                    <div>
                        <b-modal ref="modal-unsuccess" hide-footer title="Kadirov.Dev">
                            <div class="d-block text-center">
                                <h3>Ma'lumotlarni o'zgartirishda xatolik yuz berdi</h3>
                            </div>
                            <b-button
                                class="mt-3 float-end"
                                style="width: 100px"
                                variant="btn btn-primary"
                                size="md"
                                @click="errorModal"
                            >
                                OK
                            </b-button>
                        </b-modal>
                    </div>
                    <div>
                        <b-modal ref="modal-success" hide-footer title="Kadirov.Dev">
                            <div class="d-block text-center">
                                <h3>Siz ma'lumotlaringizni muvaffaqiyatli o'zgartirdingiz!</h3>
                            </div>
                            <b-button
                                class="mt-3 float-end"
                                style="width: 100px"
                                variant="btn btn-primary"
                                size="md"
                                @click="hideErrorModal"
                            >
                                OK
                            </b-button>
                        </b-modal>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import GoBack from "@/components/GoBack";
import Quote from "@/components/Quote";
import {mapActions, mapGetters} from "vuex";

export default {
    name: "RegistrationPage",
    components: {GoBack, Quote},
    computed: {
        ...mapGetters(['getUserInfo'])
    },
    methods: {
        ...mapActions(['fetchUser', 'putUserInfo']),
        editUser() {
            if (
                this.forms.email !== this.getUserInfo.email ||
                this.forms.givenName !== this.getUserInfo.givenName ||
                this.forms.familyName !== this.getUserInfo.familyName ||
                this.forms.phoneNumber !== this.getUserInfo.phoneNumber ||
                this.forms.telegramNumber !== this.getUserInfo.telegramNumber
            ) {
                this.putUserInfo({
                    id: this.forms.id,
                    email: this.forms.email,
                    givenName: this.forms.givenName,
                    familyName: this.forms.familyName,
                    phoneNumber: this.forms.phoneNumber,
                    telegramNumber: this.forms.telegramNumber,
                })
                    .then(() => {
                        this.$refs['modal-success'].show()
                        setTimeout(this.hideErrorModal, 5000)
                    })
                    .catch(() => {
                        this.$refs['modal-unsuccess'].show()
                        setTimeout(this.errorModal, 5000)
                        console.log('registration da xato')
                    })
            } else {
                this.$router.push('/cabinet')
                setTimeout(this.errorModal, 5000)
            }
        },
        hideErrorModal() {
            this.$refs['modal-success'].hide()
            this.$router.push('/cabinet')
        },
        errorModal() {

            this.$refs['modal-unsuccess'].hide()
        }
    },
    data() {
        return {
            forms: {
                id: null,
                email: "",
                givenName: "",
                familyName: "",
                phoneNumber: "",
                telegramNumber: "",
                roles: ["ROLE_USER"]
            },
            options: {placeholder: "Bog'lanish uchun telefon"}
        }
    },
    mounted() {
        this.show = true
        this.fetchUser()
            .then(() => {
                this.forms.id = this.getUserInfo.id
                this.forms.email = this.getUserInfo.email
                this.forms.givenName = this.getUserInfo.givenName
                this.forms.familyName = this.getUserInfo.familyName
                this.forms.phoneNumber = this.getUserInfo.phoneNumber
                this.forms.telegramNumber = this.getUserInfo.telegramNumber
            })
        this.forms.show = false
    }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@600&display=swap');

* {
    padding: 0;
    margin: 0;
}

section form {
    font-family: 'Comfortaa', cursive;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    margin-top: 100px;
    margin-bottom: 100px;
}

.mb-3 .form-control {
    border-radius: 10px !important;
    border: solid #80007F 1px !important;
    font-family: Comfortaa, cursive !important;
}

.tel {
    width: 100% !important;
    height: 38px !important;
    margin-bottom: 10px;
    border-radius: 10px !important;
    border: solid #80007F 1px !important;
    font-family: Comfortaa, cursive !important;
    padding-right: 15px !important;
    padding-left: 12px !important;
}

.justify-content-end .btn-primary {
    padding-left: 40px;
    padding-right: 40px;
    border-radius: 10px;
    background-color: #80007F;
    font-family: Comfortaa, cursive;
    font-size: 16px;
}

.btn-primary {
    color: #fff;
    background-color: #80007F;
    border-color: #80007F;
    padding-top: 8px;
    padding-bottom: 8px;


}

.form-control {
    color: #000000;
    padding-left: 1.5rem;
}

.ABC {
    margin-top: 100px;
    margin-bottom: 100px;
}
</style>
